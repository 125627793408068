import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/intelligent-automation-usecase.scss'
import { FaAngleLeft } from "react-icons/fa"
import CodeMapImg from '../images/intelligent-usecase/code.png'

const DigitizeProcessAutomation = () => {
    return (
        <div className="intelligent-usecase">
            <Layout>
                <Nav
                    isWhite={true}
                />

                <div className="px-5 pt-4">
                    <Link to="/intelligent-automation-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Intelligent automation</p></Link>
                </div>

                <div className="digital-usecase pb-5 pt-4">
                    <div className="bg-digitize-process"></div>
                </div>


                <div className="container">
                    <h1 className="font-weight-bold text-center mt-md-5 big-text">Digitizing paper documents to flatten the COVID-19 curve</h1>

                    <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Process automation</span>Finance</p>

                    <div style={{ borderBottom: '5px solid #E41700' }}></div>
                </div>

                <div className="py-5 my-5"></div>

                <div className="bg-client-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>The Client</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    Our clients, one of the apex monetary authorities in Africa, sought our expertise during the COVID-19 Pandemic to do two things;
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    First; owing to the behaviour shift brought on by the pandemic, which caused people to avoid surface contact, the organization wanted a way to cut down on the manual handling of paper documents to flatten the curve and still get work done.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    Second; they needed a way to approve workflows faster and more effectively, so that work was not halted for any reason.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-solution-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 mx-auto my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Our Solution</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    We came to the rescue to solve the two problems using intelligent automation.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    First, we created an online platform to digitize all paper forms and allow for secure upload and collation of documents. Then we built a software robot to automate the process of data handling. The robot performed amazing tasks including minute by minute checks using Optical Character Recognition (OCR) to scan texts on the document and figure out the intended receiver which allowed it send each document in quick succession without recording any errors.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                    For the second task, we built a system that collected requests for approval. Using machine learning, we trained that system on the approval chains for each department so that the system was able to send requests uploaded by any member of the organization’s workforce to the right individual for approval. Staff members can easily request for approval by signing in to the system, which in turn populates the fields with their rightful credentials.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 mx-auto">
                            <img src={CodeMapImg} alt="Journey Map" className="img-fluid" />
                            <p className="text-secondary text-center mt-1">Ongoing implementation of the online digitisation platform</p>
                        </div>
                    </div>
                </div>

                <div className="my-5 py-5"></div>

                <div className="bg-impact-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5">
                            <div className="col-md-6 my-5">
                                <p style={{ fontSize: '1.5rem' }} className="font-weight-bold">The Impact</p>

                                <ul>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Ensured work continuity and productivity even during the pandemic.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Saved the organization cost of paper. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Sustained employee satisfaction, when approvals were disbursed in time. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Inadvertently aided the organization to save lives by eliminating physical contact and flatten the Coronavirus curve. Promoted all-round transparency in everyday business dealings. Improved workforce performance without the stress of cumbersome manual processes.</p></li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5 my-5">
                        <div className="col-md-5 mx-auto mb-5 text-center">
                            <Link to={`/contact`} state={{ path: 'intelligent' }}><button style={{ height: '48px' }} class="btn btn-red h-100 btn-red-black px-4 no-border-radius">Get started</button></Link>
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}

export default DigitizeProcessAutomation
